// custom select
.select2-container {
  .select2-selection--single {
    height: 44px;
    border: 1px solid var(--border-color);
    border-radius: var(--primary-radius);
    .select2-selection__rendered {
      font: 15px/42px var(--primary-font);
      padding-left: 20px;
      padding-right: 30px;
    }
    .select2-selection__arrow {
      width: 30px;
      height: auto;
      bottom: 1px;
    }
  }
}
.select2-container--default {
  .select2-results__option {
    padding: 7px 15px 6px 20px;
  }
  .select2-results__option--highlighted.select2-results__option--selectable {
    background: var(--deco-color);
  }
}
.select2-dropdown {
  font: 15px/1.3 var(--primary-font);
  border-color: var(--border-color);
}

// range slider
.irs {
  &.irs--round {
    height: 10px;
    .irs-handle {
      width: 10px;
      height: 10px;
      cursor: pointer;
      background: var(--deco-color);
      border: none;
      border-radius: 50%;
      box-shadow: none;
      top: 0;
      &:before {
        content: "";
        position: absolute;
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
      }
    }
    .irs-line {
      height: 2px;
      background: var(--border-color);
      top: 4px;
    }
    .irs-bar {
      height: 2px;
      background: var(--deco-color);
      top: 4px;
    }
    .irs-from,
    .irs-single,
    .irs-to,
    .irs-max,
    .irs-min {
      display: none;
    }
  }
}