@media (min-width: $tablet-min) and (max-width: $tablet-max) {
  .header {
    &__body {
      &__left {
        margin: 0 15px 0 0;
        flex: 0 0 200px;
      }
      &__right {
        margin: 20px 0 0 0;
        align-items: center;
        justify-content: flex-end;
        display: flex;
        flex: 0 0 100%;
        column-gap: 30px;
      }
      &__row {
        &--nav {
          width: 200px;
          opacity: 0;
          visibility: hidden;
          background: var(--alabaster);
          transition: all var(--primary-transition);
          border-radius: var(--primary-radius);
          padding: 10px 14px;
          align-items: flex-end;
          flex-flow: column nowrap;
          row-gap: 10px;
          position: absolute;
          top: 80px;
          right: var(--gutter);
          z-index: 2;
          &.open {
            opacity: 1;
            visibility: visible;
          }
          &:after {
            width: 0;
            height: 0;
            content: "";
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 0;
            border-bottom: 10px solid var(--alabaster);
            position: absolute;
            top: -10px;
            right: 5px;
          }
        }
      }
    }
  }
  .site {
    &-hamburger {
      display: none;
    }
    &-help {
      justify-content: flex-end;
      row-gap: 0;
    }
    &-onpage {
      &__label {
        display: none;
      }
    }
    &-newsletter {
      &__label {
        margin: 0 30px 0 0;
      }
    }
  }
  .nav {
    &-info {
      margin: 0;
      &__list {
        align-items: flex-end;
        flex-flow: column nowrap;
        gap: 6px;
      }
      &__links {
        white-space: normal;
      }
    }
    &-options {
      margin: 0;
      &__items {
        &:before {
          margin: 0 15px;
        }
      }
    }
    &-helpers {
      &__list {
        column-gap: 15px;
      }
      &__links {
        [class^=icon-] {
          font-size: 24px;
        }
        &__txt {
          display: none;
        }
      }
    }
    &-cat {
      &__list {
        width: 200px;
      }
    }
  }
  .aside {
    margin: 0 15px 50px 0;
    flex: 0 0 200px;
  }
  .content {
    width: calc(100% - 215px);
    &--hero,
    &--full {
      width: 100%;
    }
  }
  .hero {
    &-slider {
      width: calc(100% - 264px);
    }
    &-banners {
      flex: 0 0 244px;
    }
  }
  .cat {
    &-nav {
      padding: 50px 20px 20px 30px;
      flex: 0 0 244px;
      &__link {
        margin: 34px 0 0 0;
      }
    }
    &-list {
      grid-template-columns: repeat(auto-fit, minmax(150px, 5fr));
    }
    &-slider {
      &__slide {
        width: 244px;
      }
    }
  }
  .products {
    &-top {
      &__image {
        max-width: 35%;
      }
      &__text {
        padding: 0;
      }
    }
    &-list {
      grid-template-columns: repeat(auto-fill, minmax(240px, 5fr));
      [class*="--full"] & {
        grid-template-columns: repeat(auto-fill, minmax(240px, 5fr));
      }
    }
  }
  .authenticate {
    &-login {
      padding: 0 30px 10px 0;
    }
    &-register {
      padding: 0 0 10px 30px;
    }
  }
  .cart {
    &-table {
      margin: 0 0 30px 0;
      flex: 0 0 100%;
    }
  }
  .footer {
    &__body {
      padding: 25px 0 45px 0;
      .main {
        flex-wrap: wrap;
      }
    }
    &__col {
      padding: 25px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      &--t-1 {
        width: 33.333%;
      }
      &--t-3 {
        width: 66.666%;
      }
      &--t-4 {
        width: 50%;
      }
    }
  }
}