/* media queries scss */
$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1279px;
$desktop-min: 1280px;

:root {
  /* colors with hex values */
  --scorpion: #616060;
  --tundora: #434343;
  --silver-chalice: #9f9f9f;
  --mine-shaft: #1f1f1f;
  --nobel: #b7b7b7;
  --bizarre: #ECE0D2;
  --silver: #b9b9b9;
  --white: #fff;
  --dusty-gray: #9A9A9A;
  --gallery: #ebebeb;
  --pelorous: #4a97c3;
  --san-juan: #395470;
  --domino: #8c775d;
  --alto: #d3d3d3;
  --polar: #f3f9fc;
  --black-squeeze: #f2f7fa;
  --alabaster: #f9f9f9;
  --wild-sand: #f5f5f5;
  --mantis: #74ca46;
  --punch: #e04546;


  /* reassign color vars to semantic color scheme */
  --text-color: var(--scorpion);
  --secondary-text-color: var(--tundora);
  --light-text-color: var(--silver-chalice);
  --elements-color: var(--white);
  --deco-color: var(--pelorous);
  --secondary-deco-color: var(--san-juan);

  /* font */
  --primary-font: "Rubik", sans-serif;

  /* other elelemnts */
  --primary-transition: .2s linear;
  --placeholder-color: var(--dusty-gray);
  --border-color: var(--gallery);
  --secondary-border-color: var(--silver);
  --primary-radius: 4px;
  --error-color: var(--punch);
  --gutter: 15px;

  /* media queries css */
  --mobile-max: #{$mobile-max};
  --tablet-min: #{$tablet-min};
  --tablet-max: #{$tablet-max};
  --desktop-min: #{$desktop-min};
}