// imports top
@import "config";
@import "normalize";
@import "reset";
@import "typografy";
@import "icomoon";
@import "swiper-bundle.min";
@import "select2.min";
@import "ion.rangeSlider.min";
@import "plugins";
body,
html {
  height: 100%;
}
body {
  color: var(--text-color);
  font: 15px/1.3 var(--primary-font);
}
.wrapper {
  min-height: 100%;
  flex-flow: column nowrap;
  display: flex;
}
.main {
  width: 100%;
  max-width: 1770px;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  margin: 0 auto;
  &--tight {
    max-width: 1060px;
  }
  &--flex {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
  }
  &--center {
    justify-content: center;
  }
}
.header {
  flex: none;
  .inner & {
    border-bottom: 1px solid var(--border-color);
    margin: 0 0 32px 0;
  }
  &__info {
    color: var(--bizarre);
    font: 14px/1.2 var(--primary-font);
    background: var(--domino);
    padding: 12px var(--gutter) 9px var(--gutter);
    position: relative;
    &__link {
      color: var(--bizarre);
      font-size: 12px;
      text-decoration: none;
      transition: color var(--primary-transition);
      display: inline-flex;
      position: absolute;
      top: calc(50% - 6px);
      right: var(--gutter);
      &:hover {
        color: var(--elements-color);
      }
    }
  }
  &__body {
    position: relative;
    padding: 10px 0 25px 0;
    .main {
      align-items: stretch;
    }
    &__left {
      margin: 0 30px 0 0;
      position: relative;
      flex: 0 0 260px;
    }
    &__mid {
      display: flex;
      flex-flow: row wrap;
      flex: 1 1 0;
    }
    &__right {
      text-align: right;
      margin: 0 0 0 20px;
      flex: 0 0 274px;
    }
    &__row {
      width: 100%;
      margin: auto 0 0 0;
      align-items: center;
      display: flex;
    }
  }
}
.site {
  &-logo {
    margin: 0 0 8px 0;
    display: inline-flex;
  }
  &-hamburger {
    height: 23px;
    margin: 0 0 0 10px;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity;
    position: relative;
    align-items: center;
    display: none;
    &__box {
      width: 29px;
      height: 19px;
      transform: scale(.7);
      position: relative;
      display: block;
    }
    &__inner {
      top: 50%;
      margin-top: -2px;
      display: block;
    }
    &__inner,
    &__inner:before,
    &__inner:after {
      width: 29px;
      height: 3px;
      background: var(--secondary-deco-color);
      position: absolute;
      transition: transform .15s ease;
    }
    &:hover &__inner,
    &:hover &__inner:before,
    &:hover &__inner:after {
      background: var(--secondary-deco-color);
    }
    &__inner:before,
    &__inner:after {
      content: "";
      display: block;
    }
    &__inner:before {
      top: -8px;
    }
    &__inner:after {
      top: -16px;
    }
    &--collapse &__inner {
      top: auto;
      bottom: 0;
      transition: transform .13s cubic-bezier(.55, .055, .675, .19) .13s, background var(--primary-transition);
      &:after {
        transition: top .2s .2s cubic-bezier(.33333, .66667, .66667, 1), opacity .1s linear, background var(--primary-transition);
      }
      &:before {
        transition: top .12s .2s cubic-bezier(.33333, .66667, .66667, 1), transform .13s cubic-bezier(.55, .055, .675, .19), background var(--primary-transition);
      }
    }
    &--collapse.active &__inner,
    a.active &--collapse &__inner {
      background: var(--deco-color);
      transform: translate3d(0, -7px, 0) rotate(-45deg);
      transition-delay: .22s;
      transition-timing-function: cubic-bezier(.215, .61, .355, 1);
      &:after {
        background: var(--deco-color);
        top: 0;
        opacity: 0;
        transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s .22s linear;
      }
      &:before {
        background: var(--deco-color);
        top: 0;
        transform: rotate(-90deg);
        transition: top .1s .16s cubic-bezier(.33333, 0, .66667, .33333), transform .13s .25s cubic-bezier(.215, .61, .355, 1);
      }
    }
  }
  &-help {
    color: var(--secondary-text-color);
    font: 15px/1.2 var(--primary-font);
    align-items: center;
    display: inline-flex;
    flex-flow: row wrap;
    column-gap: 18px;
    row-gap: 5px;
    &__link {
      color: var(--secondary-text-color);
      font-weight: 500;
      text-decoration: none;
      transition: color var(--primary-transition);
      align-items: center;
      display: inline-flex;
      &:hover {
        color: var(--deco-color);
      }
      [class^=icon-] {
        color: var(--deco-color);
        font-size: 30px;
        margin: 0 7px 0 0;
      }
    }
  }
  &-search {
    width: 100%;
    height: 52px;
    display: flex;
    &__hold {
      border-top: 2px solid var(--border-color);
      border-left: 2px solid var(--border-color);
      border-bottom: 2px solid var(--border-color);
      border-radius: var(--primary-radius) 0 0 var(--primary-radius);
      padding: 0 0 0 30px;
      position: relative;
      display: flex;
      flex: 1 1 0;
      .icon-search {
        color: var(--text-color);
        font-size: 20px;
        position: absolute;
        top: calc(50% - 10px);
        left: 15px;
      }
    }
    &__input {
      border: none;
    }
    .select2-container {
      max-width: 210px;
      display: none;
      &:before {
        width: 1px;
        content: "";
        background: var(--border-color);
        position: absolute;
        top: 11px;
        left: 0;
        bottom: 11px;
      }
      .select2-selection--single {
        height: 48px;
        border: none;
        .select2-selection__rendered {
          line-height: 48px;
        }
      }
    }
    & &__btn {
      border-radius: 0 var(--primary-radius) var(--primary-radius) 0;
    }
  }
  &-headlines {
    font: 15px/1.2 var(--primary-font);
    padding: 15px 0;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    gap: 15px;
    &--nowrap {
      flex-wrap: nowrap;
    }
    &--border-top {
      border-top: 1px solid var(--border-color);
      margin: 0 0 85px 0;
    }
    &--border-bottom {
      border-bottom: 1px solid var(--border-color);
      margin: 0 0 25px 0;
    }
    &--toggles {
      padding-top: 0;
      display: none;
    }
    &__wrap {
      align-items: center;
      display: inline-flex;
      gap: 15px;
    }
    :is(h1, h2, h3, h4, h5, h6) {
      padding-bottom: 0;
    }
  }
  &-onpage,
  &-sort {
    align-items: center;
    display: inline-flex;
    gap: 20px;
  }
  &-onpage {
    &__label {
      flex: 0 0 auto;
    }
  }
  &-breadcrumb {
    width: 100%;
    margin: 0 0 44px 0;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    row-gap: 20px;
    &__items {
      font: 300 13px/1.2 var(--primary-font);
      align-items: center;
      display: inline-flex;
      &:last-child {
        &:after {
          display: none;
        }
      }
      &:after {
        font-size: 10px;
        font-family: icomoon;
        content: "\e910";
        margin: 0 15px;
      }
    }
    &__links {
      color: var(--text-color);
      text-decoration: none;
      background: var(--wild-sand);
      transition: all var(--primary-transition);
      border-radius: var(--primary-radius);
      padding: 7px 14px;
      align-items: center;
      display: inline-flex;
      &:hover {
        color: var(--elements-color);
        background: var(--deco-color);
      }
    }
  }
  &-toggles {
    align-items: center;
    display: inline-flex;
    &.active {
      [class^=icon-] {
        transform: rotate(180deg);
      }
    }
    [class^=icon-] {
      font-size: 8px;
      transition: transform var(--primary-transition);
      margin: 0 0 0 8px;
    }
  }
  &-filters {
    @media (min-width: $tablet-min) {
      display: block !important;
    }
  }
  &-share {
    margin: 0 0 32px 0;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    gap: 15px;
  }
  &-brands {
    margin: 0 0 5px 0;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    &__items {
      align-items: center;
      display: inline-flex;
      &:last-child {
        &:after {
          display: none;
        }
      }
      &:after {
        font-size: 10px;
        font-family: icomoon;
        content: "\e910";
        margin: 0 15px;
      }
    }
    &__links {
      color: var(--secondary-text-color);
      font: 300 14px/1.2 var(--primary-font);
      text-decoration: none;
      transition: color var(--primary-transition);
      display: inline-block;
      &:hover {
        color: var(--deco-color);
      }
    }
  }
  &-pages {
    font: 15px/1.2 var(--primary-font);
    white-space: pre-wrap;
    align-items: center;
    display: inline-flex;
    column-gap: 10px;
    & &__links {
      height: 44px;
      transition: all var(--primary-transition);
      padding: 0 3px;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      &.active {
        width: 44px;
        color: var(--text-color);
        border: 1px solid var(--border-color);
        border-radius: var(--primary-radius);
        &:hover {
          color: var(--elements-color);
          background: var(--deco-color);
          border-color: var(--deco-color);
        }
      }
      [class^=icon-] {
        font-size: 30px;
      }
    }
  }
  &-newsletter {
    border-bottom: 1px solid var(--secondary-border-color);
    padding: 44px 0 38px 0;
    .main {
      flex-wrap: nowrap;
    }
    &__label {
      color: var(--mine-shaft);
      font: 500 21px/1.2 var(--primary-font);
      margin: 0 66px 0 0;
      align-items: center;
      display: inline-flex;
      flex: 0 0 auto;
      column-gap: 20px;
      [class^=icon-] {
        font-size: 40px;
      }
    }
    &__wrap {
      width: 100%;
      max-width: 750px;
      height: 52px;
      display: inline-flex;
    }
    &__hold {
      border-top: 2px solid var(--border-color);
      border-left: 2px solid var(--border-color);
      border-bottom: 2px solid var(--border-color);
      border-radius: var(--primary-radius) 0 0 var(--primary-radius);
      display: flex;
      flex: 1 1 0;
    }
    &__input {
      border: none;
    }
    & &__btn {
      border-radius: 0 var(--primary-radius) var(--primary-radius) 0;
    }
  }
  &-sep {
    border-bottom: 1px solid var(--border-color);
    margin: 0 0 38px 0;
    clear: both;
    &--dark {
      border-color: var(--silver);
    }
  }
}
.nav {
  &-info {
    margin: 0 50px 0 0;
    &__list {
      align-items: center;
      display: inline-flex;
      column-gap: 50px;
    }
    &__links {
      color: var(--secondary-text-color);
      font: 500 15px/1.2 var(--primary-font);
      text-decoration: none;
      text-transform: uppercase;
      white-space: nowrap;
      transition: color var(--primary-transition);
      display: inline-block;
      &--spec {
        color: var(--punch);
      }
      &.active,
      &:hover {
        color: var(--deco-color);
      }
    }
  }
  &-options {
    margin: 30px 0 42px 0;
    display: block;
    &__list {
      align-items: center;
      display: inline-flex;
    }
    &__items {
      font: 14px/1.2 var(--primary-font);
      white-space: pre-wrap;
      position: relative;
      display: inline-flex;
      &:first-child {
        &:before {
          display: none;
        }
      }
      &:before {
        width: 1px;
        content: "";
        background: var(--secondary-text-color);
        margin: 0 20px;
        display: inline-flex;
      }
      .icon-arrow-down {
        font-size: 7px;
        margin: -1px 0 0 8px;
      }
      .icon-user {
        margin: 0 6px 0 0;
      }
    }
    &__links {
      color: var(--secondary-text-color);
      text-decoration: none;
      transition: color var(--primary-transition);
      align-items: center;
      display: inline-flex;
      &.active,
      &:hover {
        color: var(--deco-color);
      }
    }
    &__drop {
      opacity: 0;
      visibility: hidden;
      white-space: normal;
      background: var(--elements-color);
      transition: all var(--primary-transition);
      border: 1px solid var(--border-color);
      border-radius: var(--primary-radius);
      padding: 5px 10px;
      position: absolute;
      top: calc(100% + 10px);
      left: -10px;
      z-index: 2;
      &.open {
        opacity: 1;
        visibility: visible;
      }
      &__items {
        padding: 5px 0;
      }
      &__links {
        color: var(--secondary-text-color);
        text-decoration: none;
        transition: color var(--primary-transition);
        display: block;
        &:hover {
          color: var(--deco-color);
        }
      }
    }
  }
  &-helpers {
    display: block;
    &__list {
      display: inline-flex;
      column-gap: 25px;
    }
    &__items {
      display: inline-flex;
    }
    &__links {
      color: var(--secondary-text-color);
      font: 14px/1.2 var(--primary-font);
      text-decoration: none;
      transition: color var(--primary-transition);
      align-items: center;
      display: inline-flex;
      flex-flow: row wrap;
      &:hover {
        color: var(--deco-color);
      }
      &__number {
        width: 20px;
        height: 20px;
        color: var(--elements-color);
        background: var(--punch);
        border-radius: 50%;
        padding: 2px 0 0 0;
        align-items: center;
        justify-content: center;
        display: inline-flex;
        flex: 0 0 auto;
      }
      &__txt {
        font-size: 12px;
        text-align: left;
        padding: 0 0 0 10px;
        strong {
          font-size: 15px;
          font-weight: 500;
        }
      }
      [class^=icon-] {
        font-size: 26px;
        margin: 0 6px 0 0;
      }
    }
  }
  &-cat {
    display: block;
    &__drop {
      height: 51px;
      color: var(--elements-color);
      font: 500 15px/1.2 var(--primary-font);
      text-decoration: none;
      background: var(--secondary-deco-color);
      border-radius: var(--primary-radius);
      padding: 1px 30px 0 20px;
      position: relative;
      align-items: center;
      display: flex;
      .icon-departments {
        margin: 0 20px 0 0;
      }
      .icon-arrow-down {
        font-size: 7px;
        position: absolute;
        top: calc(50% - 3px);
        right: 18px;
      }
    }
    &__list {
      width: 260px;
      opacity: 0;
      visibility: hidden;
      background: var(--alabaster);
      transition: all var(--primary-transition);
      border-radius: var(--primary-radius);
      padding: 10px 14px 24px 14px;
      margin: 24px 0 0 0;
      position: absolute;
      left: 0;
      z-index: 2;
      @media (min-width: $desktop-min) {
        .home & {
          min-height: 743px;
          opacity: 1;
          visibility: visible;
        }
      }
      &.open {
        opacity: 1;
        visibility: visible;
      }
      &:after {
        width: 0;
        height: 0;
        content: "";
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 0;
        border-bottom: 13px solid var(--alabaster);
        position: absolute;
        top: -13px;
        left: 20px;
      }
      &__items {
        border-bottom: 1px solid var(--alto);
      }
      &__links {
        color: var(--mine-shaft);
        text-decoration: none;
        transition: color var(--primary-transition);
        padding: 11px 5px 10px 5px;
        display: block;
        &--sub {
          padding-right: 15px;
          position: relative;
          &:after {
            font-size: 9px;
            font-family: icomoon;
            content: "\e902";
            position: absolute;
            top: calc(50% - 5px);
            right: 4px;
          }
        }
        &.active,
        &:hover {
          color: var(--deco-color);
        }
      }
    }
  }
  &-inner {
    border: 2px solid var(--border-color);
    border-radius: var(--primary-radius);
    padding: 5px 14px;
    margin: 0 0 10px 0;
    @media (min-width: $tablet-min) {
      display: block !important;
    }
    &__items {
      border-bottom: 1px solid var(--alto);
      &:last-child {
        border-bottom: none;
      }
      & &:first-child {
        border-top: 1px solid var(--alto);
      }
      & &--last {
        border: none;
        &:first-child {
          border: none;
          padding-top: 4px;
        }
        &:last-child {
          padding-bottom: 16px;
        }
      }
    }
    &__links {
      font: 15px/1.2 var(--primary-font);
      padding: 12px 0;
      display: block;
      &--sub {
        padding-left: 20px;
      }
      &--drop {
        position: relative;
        &.active {
          &:before {
            transform: rotate(180deg);
          }
        }
        &:before {
          font-size: 8px;
          font-family: icomoon;
          content: "\e900";
          transition: transform var(--primary-transition);
          position: absolute;
          top: calc(50% - 5px);
          left: 5px;
        }
      }
      &--last {
        padding: 10px 0 10px 30px;
      }
    }
    &__last {
      display: none;
      &.open {
        display: block;
      }
    }
    &__numbers {
      color: var(--light-text-color);
      font-size: 13px;
    }
  }
  &-links {
    &__list {
      display: flex;
      flex-flow: row wrap;
      column-gap: 20px;
      row-gap: 13px;
    }
    [class*="--col-1"] &__items {
      width: 100%;
    }
    [class*="--col-2"] &__items {
      width: calc(50% - 10px);
    }
  }
}
.body {
  padding-bottom: 60px;
  isolation: isolate;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
}
.aside {
  margin: 0 30px 50px 0;
  align-self: flex-start;
  flex: 0 0 260px;
}
.side {
  &-range {
    padding: 0 0 40px 0;
    &__hold {
      margin: 0 0 18px 0;
      align-items: center;
      display: flex;
      column-gap: 15px;
    }
  }
  &-options {
    padding: 0 0 10px 0;
    &__items {
      padding: 0 0 15px 0;
    }
    &__link {
      font: 13px/1.2 var(--primary-font);
      margin: 8px 0 0 0;
      align-items: center;
      display: inline-flex;
      .icon-close {
        font-size: 10px;
        margin: 0 6px 0 0;
      }
    }
  }
}
.content {
  width: calc(100% - 290px);
  margin: 0 0 0 auto;
  align-self: flex-start;
  &--full {
    width: 100%;
  }
}
.hero {
  &-wrap {
    width: 100%;
    margin: 0 0 25px 0;
    display: flex;
  }
  &-slider {
    width: calc(100% - 294px);
    margin: 0 20px 0 0;
    &__slide {
      height: 428px;
      &:after {
        content: "";
        background: rgba(37, 37, 37, .13);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__text {
      max-width: 500px;
      color: var(--elements-color);
      padding: 30px 48px 60px 48px;
      align-items: flex-start;
      justify-content: center;
      display: flex;
      flex-flow: column nowrap;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      .h1 {
        color: var(--elements-color);
      }
      .h4 {
        color: var(--elements-color);
        font: 300 21px/1.2 var(--primary-font);
      }
      .icon-arrow-right-long {
        font-size: 30px;
        margin: -10px 0 -10px 22px;
      }
    }
    .swiper-pagination {
      text-align: left;
      padding: 0 0 0 48px;
      bottom: 30px;
      .swiper-pagination-bullet {
        opacity: 1;
        background: var(--alto);
        transition: all var(--primary-transition);
        margin: 0 14px 0 0;
        &.swiper-pagination-bullet-active {
          width: 30px;
          background: var(--deco-color);
          border-radius: var(--primary-radius);
        }
      }
    }
  }
  &-banners {
    display: flex;
    flex-flow: row wrap;
    flex: 0 0 274px;
    gap: 22px;
    &__items {
      width: 100%;
      color: var(--elements-color);
      font: 300 22px/1.3 var(--primary-font);
      padding: 25px;
      align-self: stretch;
      align-items: flex-start;
      justify-content: flex-end;
      display: flex;
      flex-flow: column nowrap;
      &--type-1 {
        background: var(--deco-color);
      }
      &--type-2 {
        background: var(--domino);
      }
      [class^=icon-] {
        font-size: 64px;
        margin: 0 0 10px 0;
      }
    }
    &__txt {
      font-weight: 500;
    }
  }
}
.cat {
  &-wrap {
    width: 100%;
    margin: 0 0 80px 0;
    display: flex;
  }
  &-nav {
    background: var(--polar);
    padding: 50px 20px 20px 64px;
    margin: 0 20px 0 0;
    position: relative;
    flex: 0 0 274px;
    h3 {
      font: 500 34px/1.14 var(--primary-font);
    }
    &__controls {
      width: 40px;
      height: 40px;
      color: var(--deco-color);
      cursor: pointer;
      border: 1px solid var(--deco-color);
      border-radius: var(--primary-radius);
      margin: 0 10px 0 0;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      &[class*="-disabled"] {
        color: var(--silver);
        border-color: var(--silver);
      }
    }
    &__link {
      color: var(--secondary-deco-color);
      font: 500 14px/1.2 var(--primary-font);
      text-decoration: none;
      text-transform: uppercase;
      transition: color var(--primary-transition);
      margin: 54px 0 0 0;
      display: inline-block;
      &:hover {
        color: var(--deco-color);
      }
    }
  }
  &-list {
    margin: 0 0 40px 0;
    grid-template-columns: repeat(auto-fit, minmax(220px, 5fr));
    display: grid;
    gap: 20px;
    &__items {
      display: flex;
    }
    &__links {
      width: 100%;
      color: var(--mine-shaft);
      text-decoration: none;
      background: var(--polar);
      transition: color var(--primary-transition);
      padding: 25px 14px 30px 14px;
      display: block;
      &:hover {
        color: var(--deco-color);
      }
      img {
        width: 100%;
      }
    }
    &__txt {
      font: 500 14px/1.2 var(--primary-font);
      text-transform: uppercase;
      text-align: center;
      padding: 14px 0 0 0;
      display: block;
    }
  }
  &-slider {
    width: 100%;
    &__slide {
      width: 274px;
    }
  }
  &-desc {
    background: var(--black-squeeze);
    padding: 32px 50px 50px 50px;
    h2 {
      font: 500 36px/1.2 var(--primary-font);
    }
    & > :last-child {
      padding-bottom: 0;
    }
  }
}
.products {
  &-top {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 32px;
    &__items {
      font: 14px/1.48 var(--primary-font);
      padding: 40px 0 0 0;
      &:first-child,
      &:last-child {
        padding-top: 0;
      }
      a:not([class]) {
        color: var(--deco-color);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__links {
      color: var(--text-color);
      text-decoration: none;
      transition: color var(--primary-transition);
      display: flex;
      column-gap: 12px;
      &:hover {
        color: var(--deco-color);
      }
    }
    &__image {
      width: 124px;
      align-self: flex-start;
      flex: 0 0 auto;
    }
    &__text {
      padding: 10px 0 0 0;
      p {
        font: 14px/1.48 var(--primary-font);
      }
      & > :last-child {
        padding-bottom: 0;
      }
    }
  }
  &-list {
    margin: 0 0 50px 0;
    overflow: hidden;
    grid-template-columns: repeat(auto-fill, minmax(250px, 5fr));
    display: grid;
    row-gap: 35px;
    [class*="--full"] & {
      grid-template-columns: repeat(auto-fill, minmax(300px, 5fr));
    }
    &__items {
      padding: 0 0 40px 0;
      position: relative;
      &:after {
        width: 1px;
        max-height: 226px;
        content: "";
        background: var(--border-color);
        position: absolute;
        top: 45px;
        right: -1px;
        bottom: 45px;
        z-index: 1;
      }
      &:hover {
        .btn {
          opacity: 1;
          visibility: visible;
        }
      }
      .btn {
        opacity: 0;
        visibility: hidden;
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        left: 30px;
        right: 30px;
        bottom: 0;
      }
    }
    &__links {
      color: var(--text-color);
      text-decoration: none;
      padding: 0 8px;
      display: block;
    }
    &__image {
      width: 100%;
    }
    &__text {
      text-align: center;
      background: var(--elements-color);
      transition: transform var(--primary-transition);
      padding: 12px 10px 0 10px;
      display: block;
      p {
        font: 14px/1.48 var(--primary-font);
      }
      & > :last-child {
        padding-bottom: 0;
      }
    }
    &__items:hover &__text {
      transform: translateY(-28px);
    }
    &__price {
      color: var(--deco-color);
      font: 500 16px/1.2 var(--primary-font);
      vertical-align: middle;
      padding: 0 0 6px 0;
      display: inline-flex;
      &--old {
        color: var(--nobel);
        font-size: 14px;
        padding: 0 0 3px 0;
      }
    }
  }
  &-gallery {
    width: calc(50% - 30px);
    max-width: 749px;
    margin: 0 0 0 auto;
    align-self: flex-start;
    &-main {
      margin: 0 0 10px 0;
      &__slide {
        border: 1px solid var(--border-color);
        &:after {
          color: var(--text-color);
          font-size: 20px;
          font-family: icomoon;
          content: "\e90f";
          position: absolute;
          right: 20px;
          bottom: 20px;
        }
        img {
          width: 100%;
        }
      }
    }
    &-thumb {
      width: 100%;
      max-width: 670px;
      padding: 0 50px;
      margin: 0 auto;
      position: relative;
      &__slide {
        width: 106px;
        cursor: pointer;
        border: 1px solid var(--border-color);
        img {
          width: 100%;
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        color: var(--mine-shaft);
        &:after {
          font-size: 22px;
        }
      }
      .swiper-button-prev {
        left: 0;
      }
      .swiper-button-next {
        right: 0;
      }
    }
  }
  &-desc {
    width: 50%;
    margin: 0 0 45px 30px;
    align-self: flex-start;
    &__wishlist {
      color: var(--secondary-text-color);
      font-size: 20px;
      text-decoration: none;
      transition: color var(--primary-transition);
      display: inline-block;
      &:hover {
        color: var(--deco-color);
      }
    }
    &__sku {
      color: var(--light-text-color);
      font: 14px/1.2 var(--primary-font);
      margin: 0 0 22px 0;
      display: block;
    }
    &__price {
      color: var(--deco-color);
      font: 500 30px/1.2 var(--primary-font);
      vertical-align: baseline;
      margin: 0 0 32px 0;
      display: inline-block;
      &--old {
        color: var(--nobel);
        font: 17px/1.2 var(--primary-font);
        margin: 0 0 0 10px;
      }
    }
    &__stock {
      margin: 0 0 18px 0;
      align-items: center;
      display: flex;
      &--in {
        &:before {
          background: var(--mantis);
        }
      }
      &--out {
        &:before {
          background: var(--punch);
        }
      }
      &:before {
        width: 9px;
        height: 9px;
        content: "";
        border-radius: 50%;
        margin: -2px 15px 0 0;
        display: inline-block;
      }
    }
  }
  &-related {
    width: 100%;
    border-top: 1px solid var(--border-color);
    padding: 46px 0;
    margin: 0 0 70px 0;
    &__slide {
      color: var(--text-color);
      text-align: center;
      text-decoration: none;
      transition: color var(--primary-transition);
      padding: 0 10px;
      &:hover {
        color: var(--deco-color);
      }
      &:after {
        width: 1px;
        max-height: 215px;
        content: "";
        background: var(--border-color);
        position: absolute;
        top: 10px;
        right: -1px;
        bottom: 50px;
      }
    }
    &__image {
      width: 100%;
    }
    &__text {
      padding: 0 0 15px 0;
      p {
        font: 14px/1.48 var(--primary-font);
      }
      & > :last-child {
        padding-bottom: 0;
      }
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        opacity: 1;
        background: var(--alto);
        transition: all var(--primary-transition);
        margin: 0 14px 0 0;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: -7px;
          left: -7px;
          right: -7px;
          bottom: -7px;
        }
        &.swiper-pagination-bullet-active {
          width: 30px;
          background: var(--deco-color);
          border-radius: var(--primary-radius);
        }
      }
    }
  }
  &-viewed {
    width: 100%;
    border-top: 1px solid var(--border-color);
    padding: 46px 0;
    &__slide {
      color: var(--text-color);
      text-decoration: none;
      transition: color var(--primary-transition);
      align-items: center;
      display: flex;
      column-gap: 15px;
      &:hover {
        color: var(--deco-color);
      }
      &:after {
        width: 1px;
        height: 84px;
        content: "";
        background: var(--border-color);
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        right: -1px;
      }
    }
    &__image {
      max-width: 134px;
      flex: 0 0 auto;
    }
    &__text {
      padding: 0 15px 0 0;
      p {
        font: 14px/1.48 var(--primary-font);
      }
      & > :last-child {
        padding-bottom: 0;
      }
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        opacity: 1;
        background: var(--alto);
        transition: all var(--primary-transition);
        margin: 0 14px 0 0;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: -7px;
          left: -7px;
          right: -7px;
          bottom: -7px;
        }
        &.swiper-pagination-bullet-active {
          width: 30px;
          background: var(--deco-color);
          border-radius: var(--primary-radius);
        }
      }
    }
  }
}
.authenticate {
  &-login {
    width: 50%;
    padding: 0 85px 10px 0;
    margin: 20px 0;
    align-self: flex-start;
  }
  &-register {
    width: 50%;
    border-left: 1px solid var(--border-color);
    padding: 0 0 10px 85px;
    margin: 20px 0;
    align-self: flex-start;
  }
}
.form {
  font: 15px/1.2 var(--primary-font);
  display: flex;
  flex-flow: row wrap;
  &__items {
    width: 100%;
    margin: 0 0 25px 0;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
  }
  &__label {
    width: 100%;
    font: 500 16px/1.2 var(--primary-font);
    padding: 0 0 10px 0;
    display: block;
  }
}
.qty {
  width: 100%;
  max-width: 170px;
  position: relative;
  &__links {
    width: 25px;
    height: 25px;
    color: var(--text-color);
    font: 300 16px/1 var(--primary-font);
    text-decoration: none;
    background: var(--gallery);
    transition: all var(--primary-transition);
    border-radius: var(--primary-radius);
    padding: 0 0 1px 0;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    position: absolute;
    top: calc(50% - 12px);
    &--plus {
      right: 41px;
    }
    &--minus {
      right: 9px;
    }
    &:hover {
      color: var(--elements-color);
      background: var(--deco-color);
    }
  }
  &__input {
    padding-right: 75px;
  }
}
.cart {
  &-table {
    margin: 0 40px 30px 0;
    align-self: flex-start;
    flex: 1 1 0;
    th,
    td {
      border-bottom: 1px solid var(--border-color);
      padding: 20px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    th {
      font: 15px/1.2 var(--primary-font);
    }
    td {
      font: 14px/1.2 var(--primary-font);
      &:first-child {
        width: 1%;
      }
    }
    &__image {
      border: 1px solid var(--border-color);
      display: inline-block;
      img {
        width: 105px;
      }
    }
    &__price {
      font: 500 16px/1.2 var(--primary-font);
    }
  }
  &-summary {
    width: 100%;
    max-width: 405px;
    font: 15px/1.4 var(--primary-font);
    background: var(--alabaster);
    padding: 30px 35px 35px 35px;
    margin: 0 0 30px auto;
    align-self: flex-start;
    flex: 0 0 auto;
    &__list {
      padding: 2px 0 30px 0;
      align-items: center;
      grid-template-columns: repeat(2, 1fr);
      display: grid;
      column-gap: 20px;
      row-gap: 9px;
      &--total {
        font-size: 17px;
      }
    }
    &__advantage {
      padding: 10px 0 0 45px;
      position: relative;
      strong {
        font-weight: 500;
      }
      [class^=icon-] {
        color: var(--deco-color);
        font-size: 20px;
        position: absolute;
        left: 0;
      }
    }
  }
}
.partners {
  &-slider {
    width: 100%;
    border: 2px solid var(--border-color);
    border-radius: var(--primary-radius);
    padding: 0 10%;
    margin: 0 0 56px 0;
    position: relative;
    .swiper-button-prev,
    .swiper-button-next {
      color: var(--mine-shaft);
      &:after {
        font-size: 22px;
      }
    }
    .swiper-button-prev {
      left: 30px;
    }
    .swiper-button-next {
      right: 30px;
    }
    &__slide {
      height: auto;
      align-self: stretch;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      img {
        max-width: 100%;
        max-height: 155px;
      }
    }
  }
}
.footer {
  font: 14px/1.2 var(--primary-font);
  background: var(--alabaster);
  flex: none;
  &__body {
    padding: 45px 0;
    .main {
      flex-wrap: nowrap;
    }
  }
  &__col {
    padding: 25px 25px 0 25px;
    position: relative;
    align-self: stretch;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border-left: 1px solid var(--secondary-border-color);
      padding-right: 0;
    }
    &--1 {
      width: 22%;
    }
    &--2 {
      width: 34%;
    }
    :is(h2 > a, h3 > a, h4 > a, h5 > a, h6 > a) {
      color: var(--mine-shaft);
      text-decoration: none;
      &:hover {
        color: var(--deco-color);
      }
    }
    p {
      line-height: 1.3;
    }
  }
  &__wrap {
    padding-left: 60px;
    & > :last-child {
      padding-bottom: 0;
    }
  }
  &__icons {
    font-size: 40px;
    position: absolute;
  }
  &__pin {
    align-items: center;
    display: inline-flex;
    gap: 8px;
    &:hover {
      [class^=icon-] {
        color: var(--deco-color);
      }
    }
    [class^=icon-] {
      color: var(--mine-shaft);
      font-size: 17px;
      transition: color var(--primary-transition);
    }
  }
  &__payments {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    gap: 22px;
    &__items {
      align-items: center;
      display: inline-flex;
    }
  }
  &__copy {
    color: var(--silver);
    background: var(--domino);
    padding: 28px 0 23px 0;
  }
}
input:where(:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"])),
textarea {
  width: 100%;
  color: var(--text-color);
  font: 15px var(--primary-font);
  transition: border var(--primary-transition);
  border: 1px solid var(--border-color);
  border-radius: var(--primary-radius);
  padding: 12px 20px;
}
textarea {
  height: 150px;
  resize: none;
  display: block;
  &[rows] {
    height: auto;
  }
}
input:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"])::placeholder,
textarea::placeholder {
  color: var(--placeholder-color) !important;
  opacity: 1;
}
input:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"]):focus::placeholder,
textarea:focus::placeholder {
  color: transparent !important;
}
input.error:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"]),
textarea.error {
  border: 1px solid var(--error-color) !important;
}
input[type=number] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
input[type=checkbox],
input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  & + label {
    min-height: 17px;
    font: 15px/1.2 var(--primary-font);
    letter-spacing: .05em;
    cursor: pointer;
    white-space: pre-wrap;
    padding: 0 0 0 30px;
    position: relative;
    display: inline-block;
    &:before {
      width: 17px;
      height: 17px;
      content: "";
      box-sizing: border-box;
      background: var(--elements-color);
      border: 1px solid var(--alto);
      transition: all var(--primary-transition);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &.error + label {
    &:before {
      border-color: var(--error-color);
    }
  }
  &:checked {
    & + label {
      &:before {
        background: var(--deco-color);
        border-color: var(--deco-color);
      }
    }
  }
}
input[type=checkbox] {
  & + label {
    &:before {
      border-radius: var(--primary-radius);
    }
  }
}
input[type=radio] {
  & + label {
    &:before {
      border-radius: 50%;
    }
  }
}
.btn {
  font: 500 15px/1.2 var(--primary-font);
  text-decoration: none;
  transition: all var(--primary-transition);
  border: 2px solid var(--deco-color);
  border-radius: var(--primary-radius);
  padding: 17px 40px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  &--primary {
    color: var(--elements-color);
    background: var(--deco-color);
    &:hover {
      background: var(--secondary-deco-color);
      border-color: var(--secondary-deco-color);
    }
  }
  &--secondary {
    color: var(--deco-color);
    background: var(--elements-color);
    &:hover {
      color: var(--elements-color);
      background: var(--deco-color);
    }
  }
  &--med {
    font-size: 14px;
    padding: 12px 25px;
  }
  &--wide {
    width: 100%;
    max-width: 270px;
  }
  &--block {
    width: 100%;
  }
}
.fl {
  float: left !important;
}
.fr {
  float: right !important;
}
.p-t-0 {
  padding-top: 0 !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-t-15 {
  padding-top: 15px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.p-t-25 {
  padding-top: 25px !important;
}
.p-l-0 {
  padding-left: 0 !important;
}
.p-l-5 {
  padding-left: 5px !important;
}
.p-l-10 {
  padding-left: 10px !important;
}
.p-l-15 {
  padding-left: 15px !important;
}
.p-l-20 {
  padding-left: 20px !important;
}
.p-l-25 {
  padding-left: 25px !important;
}
.p-r-0 {
  padding-right: 0 !important;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-r-10 {
  padding-right: 10px !important;
}
.p-r-15 {
  padding-right: 15px !important;
}
.p-r-20 {
  padding-right: 20px !important;
}
.p-r-25 {
  padding-right: 25px !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.p-b-5 {
  padding-bottom: 5px !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.p-b-15 {
  padding-bottom: 15px !important;
}
.p-b-20 {
  padding-bottom: 20px !important;
}
.p-b-25 {
  padding-bottom: 25px !important;
}
.m-t-0 {
  margin-top: 0 !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-25 {
  margin-top: 25px !important;
}
.m-t-auto {
  margin-top: auto !important;
}
.m-l-0 {
  margin-left: 0 !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-l-15 {
  margin-left: 15px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-l-25 {
  margin-left: 25px !important;
}
.m-l-auto {
  margin-left: auto !important;
}
.m-r-0 {
  margin-right: 0 !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-r-15 {
  margin-right: 15px !important;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-r-25 {
  margin-right: 25px !important;
}
.m-r-auto {
  margin-right: auto !important;
}
.m-b-0 {
  margin-bottom: 0 !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-25 {
  margin-bottom: 25px !important;
}
.m-b-auto {
  margin-bottom: auto !important;
}
.clearfix:after,
.clear {
  height: 0;
  content: "";
  clear: both;
  display: table;
}
.clear {
  width: 100%;
}
.visually-hidden {
  position: absolute;
  top: auto;
  left: -99999px;
}
// imports bottom
@import "tablet";
@import "mobile";