h1,
.h1 {
  color: var(--mine-shaft);
  font: 500 44px/1.1 var(--primary-font);
  padding: 0 0 24px 0;
  @media (max-width: $mobile-max) {
    font-size: 34px;
  }
}
h2,
.h2 {
  color: var(--mine-shaft);
  font: 24px/1.4 var(--primary-font);
  padding: 0 0 28px 0;
}
h3,
.h3 {
  color: var(--mine-shaft);
  font: 500 24px/1.4 var(--primary-font);
  padding: 0 0 24px 0;
}
h4,
.h4 {
  color: var(--mine-shaft);
  font: 500 22px/1.4 var(--primary-font);
  padding: 0 0 24px 0;
}
h5,
.h5 {
  color: var(--mine-shaft);
  font: 500 16px/1.4 var(--primary-font);
  padding: 0 0 24px 0;
}
h6,
.h6 {
  color: var(--mine-shaft);
  font: 500 15px/1.4 var(--primary-font);
  padding: 0 0 24px 0;
}
ul,
ol {
  &:not([class]) {
    font: 15px/1.54 var(--primary-font);
    padding: 0 0 24px 0;
    li {
      &:before {
        display: inline-block;
      }
    }
  }
}
ul {
  &:not([class]) {
    li {
      &:before {
        width: 5px;
        height: 5px;
        content: "";
        background: var(--text-color);
        border-radius: 50%;
        margin: 0 10px 0 0;
        position: relative;
        top: -3px;
      }
    }
  }
}
ol {
  &:not([class]) {
    counter-reset: li;
    li {
      &:before {
        font-weight: 500;
        content: counter(li) ".";
        counter-increment: li;
        margin: 0 7px 0 0;
      }
    }
  }
}
p {
  font: 15px/1.54 var(--primary-font);
  padding: 0 0 24px 0;
}
a {
  &:not([class]) {
    color: var(--text-color);
    text-decoration: underline;
    transition: color var(--primary-transition);
    &:hover {
      text-decoration: none;
    }
  }
  &.regular {
    color: var(--text-color);
    text-decoration: none;
    transition: color var(--primary-transition);
    &.active,
    &:hover {
      color: var(--deco-color);
    }
  }
  &[href^="mailto:"] {
    word-break: break-all;
  }
}
.text-color {
  color: var(--text-color) !important;
}
.text-color-light {
  color: var(--light-text-color) !important;
}
.text-color-deco {
  color: var(--deco-color) !important;
}
.text-color-error {
  color: var(--error-color) !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-normal {
  font-weight: normal !important;
}
.text-medium {
  font-weight: 500 !important;
}