@media (max-width: $mobile-max) {
  body {
    min-width: 360px;
  }
  .header {
    &__body {
      &__left {
        margin: 0 15px 0 0;
        position: static;
        flex: 0 0 100px;
      }
      &__mid {
        flex: none
      }
      &__right {
        margin: 20px 0 0 0;
        justify-content: flex-start;
        display: flex;
        flex-flow: column nowrap;
        flex: 1 1 0;
        column-gap: 30px;
      }
      &__row {
        &--nav {
          width: 200px;
          opacity: 0;
          visibility: hidden;
          background: var(--alabaster);
          transition: all var(--primary-transition);
          border-radius: var(--primary-radius);
          padding: 10px 14px;
          align-items: flex-end;
          flex-flow: column nowrap;
          row-gap: 10px;
          position: absolute;
          top: 110px;
          right: var(--gutter);
          z-index: 2;
          &.open {
            opacity: 1;
            visibility: visible;
          }
          &:after {
            width: 0;
            height: 0;
            content: "";
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 0;
            border-bottom: 10px solid var(--alabaster);
            position: absolute;
            top: -10px;
            right: 5px;
          }
        }
      }
    }
  }
  .site {
    &-logo {
      img {
        width: 100px;
      }
    }
    &-hamburger {
      display: none;
    }
    &-help {
      justify-content: flex-end;
      row-gap: 0;
    }
    &-search {
      margin: 24px 0 0 0;
      &__btn {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    &-headlines {
      gap: 10px;
      &--border-top {
        margin: 0 0 25px 0;
      }
      &--toggles {
        padding-top: 5px;
        display: flex;
      }
      &__wrap {
        gap: 10px;
      }
    }
    &-onpage {
      &__label {
        display: none;
      }
    }
    &-breadcrumb {
      display: none;
    }
    &-filters {
      display: none;
    }
    &-newsletter {
      padding: 38px 0 38px 0;
      .main {
        flex-flow: column nowrap;
      }
      &__label {
        margin: 0 0 20px 0;
      }
    }
  }
  .nav {
    &-info {
      margin: 0;
      &__list {
        align-items: flex-end;
        flex-flow: column nowrap;
        gap: 6px;
      }
      &__links {
        white-space: normal;
      }
    }
    &-options {
      margin: 0 0 28px 0;
      &__items {
        &:before {
          margin: 0 15px;
        }
        .icon-user {
          display: none;
        }
      }
    }
    &-helpers {
      &__list {
        column-gap: 15px;
      }
      &__links {
        [class^=icon-] {
          font-size: 24px;
        }
        &__txt {
          display: none;
        }
      }
    }
    &-cat {
      &__drop {
        height: 40px;
        display: inline-flex;
        .icon-departments {
          margin: 0 10px 0 0;
        }
        &__txt {
          display: none;
        }
      }
      &__list {
        width: auto;
        left: var(--gutter);
        right: var(--gutter);
      }
    }
    &-inner {
      display: none;
    }
  }
  .aside {
    margin: 0 0 30px 0;
    flex: 0 0 100%;
  }
  .content {
    width: 100%;
    &--hero,
    &--full {
      width: 100%;
    }
  }
  .hero {
    &-wrap {
      flex-wrap: wrap;
    }
    &-slider {
      width: 100%;
      margin: 0 0 15px 0;
      &__text {
        padding: 25px 25px 50px 25px;
        .h1 {
          color: var(--elements-color);
        }
        .h4 {
          color: var(--elements-color);
          font: 300 21px/1.2 var(--primary-font);
        }
      }
      .swiper-pagination {
        padding: 0 0 0 25px;
        bottom: 25px;
      }
    }
    &-banners {
      grid-template-columns: repeat(2, 1fr);
      display: grid;
      flex: 0 0 100%;
      gap: 15px;
      &__items {
        font-size: 15px;
        padding: 15px;
        [class^=icon-] {
          font-size: 40px;
          margin: 0 0 10px 0;
        }
      }
    }
  }
  .cat {
    &-wrap {
      margin: 0 0 30px 0;
      flex-wrap: wrap;
    }
    &-nav {
      padding: 15px;
      margin: 0 0 15px 0;
      flex: 0 0 100%;
      &__controls {
        display: none;
      }
      &__link {
        margin: -10px 0 0 0;
        display: block;
      }
    }
    &-list {
      grid-template-columns: repeat(auto-fit, minmax(150px, 5fr));
      gap: 15px;
    }
    &-slider {
      &__slide {
        width: 150px;
      }
    }
    &-desc {
      padding: 25px;
    }
  }
  .products {
    &-top {
      &__items {
        padding: 0;
      }
      &__image {
        max-width: 35%;
      }
      &__text {
        padding: 0;
        p {
          font-size: 13px;
        }
      }
    }
    &-list {
      grid-template-columns: repeat(auto-fill, minmax(165px, 5fr));
      [class*="--full"] & {
        grid-template-columns: repeat(auto-fill, minmax(165px, 5fr));
      }
      &__items {
        .btn {
          left: 15px;
          right: 15px;
        }
      }
      &__text {
        p {
          font-size: 13px;
        }
      }
    }
    &-gallery {
      width: 100%;
      max-width: none;
      margin: 0 0 45px 0;
    }
    &-desc {
      width: 100%;
      margin: 0 0 45px 0;
    }
    &-related,
    &-viewed {
      &__text {
        p {
          font-size: 13px;
        }
      }
    }
  }
  .authenticate {
    &-login {
      width: 100%;
      padding: 0 0 10px 0;
      margin: 0;
    }
    &-register {
      width: 100%;
      border-left: none;
      padding: 10px 0 0 0;
      margin: 0;
    }
  }
  .cart {
    &-table {
      border-top: 1px solid var(--border-color);
      margin: 0 0 30px 0;
      flex: 0 0 100%;
      thead {
        display: none;
      }
      tbody {
        grid-template-columns: repeat(1, 1fr);
        display: grid;
      }
      tr {
        border-bottom: 1px solid var(--border-color);
        padding: 15px 0;
        grid-template-columns: 115px 1fr auto;
        align-items: center;
        display: grid;
        gap: 15px;
      }
      td {
        border: none;
        padding: 0;
        &:first-child {
          width: auto;
        }
        &:nth-of-type(3),
        &:nth-of-type(6) {
          text-align: right;
        }
        &:nth-of-type(5) * {
          display: none;
        }
      }
      &__image {
        img {
          width: 113px;
        }
      }
    }
    &-summary {
      max-width: none;
    }
  }
  .footer {
    &__body {
      padding: 15px 0;
      .main {
        flex-wrap: wrap;
      }
    }
    &__col {
      border-bottom: 1px solid var(--secondary-border-color);
      padding: 25px 0;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border: none;
        padding-right: 0;
      }
      &--1,
      &--2 {
        width: 100%;
      }
    }
  }
}